<template>
  <div class="loginPanel" ref="weChatCodeLogin">
  </div>
</template>

<script setup>
// 初始化
import * as wx from "@wecom/jssdk";
import {ref, onMounted } from "vue";

const weChatCodeLogin = ref()

onMounted(() => {
  const wwLogin = wx.createWWLoginPanel({
    el: '#qr_login',
    params: {
      appid: 'ww8e1afaee8085d9e9',
      agentid: '1000152',
      redirect_uri: 'http%3A%2F%2Flegal-kftest.onewo.com%2F',
      state: 'state',
      redirect_type: 'callback'
    },
    onCheckWeComLogin({ isWeComLogin }) {
      console.log(isWeComLogin)
    },
    onLoginSuccess({ code }) {
      console.log({ code })
    },
    onLoginFail(err) {
      console.log(err)
    }
  })
  weChatCodeLogin.value.appendChild(wwLogin.el);
  // 卸载登录面板
  // wwLogin.unmount()
})
</script>

<style scoped>
.loginPanel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>