import { createApp } from 'vue'
import App from './App.vue'
import ElementUI from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/plugin/router/index'
import store from '@/plugin/store/index.js'

const app = createApp(App)
app.use(ElementUI)
app.use(router)
app.use(store)
app.mount('#app')
