<template>
  <div class="foot">
    <div class="button">
      <div class="inner">
        <el-avatar class="avatar">{{ store.getters.getCurrentUser.name.substring(0, 1).toUpperCase() }}</el-avatar>
        <el-text size="large" class="text-area">{{ store.getters.getCurrentUser.name }}</el-text>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore()


</script>

<style scoped>
.foot {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 100%;
}

.button:hover {
  cursor: pointer;
  background-color: #dadee7;
  border-radius: 10px;
}

.inner {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: block;
  display: flex;
  align-items: center;
}

.text-area {
  margin-left: 10px;
  color: black;
}

.avatar {
  background-color: #269171;
}
</style>