import { createStore } from "vuex";
import { Chat_State } from "@/js/constant/constant"

export default createStore({
    state: {
        // 当前用户
        user: {
            name: '',
            sap: '',
            token: ''
        },
        // 当前用户的所有聊天记录
        currentRecord: [],
        currentRequestId: "",
        // 当前聊天
        currentChat: {
            requestId: '',
            title: '',
            messages: []
        },
        currentChatState: Chat_State.INIT,
        currentSendable: true,
        currentWechatCode: {
            code: '',
            useable: true
        },
        artificial: false
    },
    getters: {
        getCurrentUser: (state) => state.user,
        getCurrentRecord: (state) => {
            const record = [];
            state.currentRecord.forEach(chat => {
                if (chat.requestId !== "ef3a6e73-0cb5-4b1b-ba71-9a0545cdad26" + "-" + state.user.sap
                    && chat.requestId !== "a2174eac-6e96-4546-bbde-425b8e962081" + "-" + state.user.sap
                    && chat.requestId !== "abcd974c-9061-48f3-9b4b-e3462cfc6665" + "-" + state.user.sap) {
                    record.push(chat)
                }
            })
            return record;
        },
        getCurrentChat: (state) => state.currentChat,
        getCurrentRequestId: (state) => state.currentRequestId,
        getCurrentMessages: (state) => state.currentChat.messages,
        getCurrentDefaultRecord: (state) => {
            const record = [];
            state.currentRecord.forEach(chat => {
                if (chat.requestId === "ef3a6e73-0cb5-4b1b-ba71-9a0545cdad26" + "-" + state.user.sap
                    || chat.requestId === "a2174eac-6e96-4546-bbde-425b8e962081" + "-" + state.user.sap
                    || chat.requestId === "abcd974c-9061-48f3-9b4b-e3462cfc6665" + "-" + state.user.sap) {
                    record.push(chat)
                }
            })
            return record;
        },
        getCurrentChatState: (state) => state.currentChatState,
        getAllCurrentRecord: (state) => state.currentRecord,
        getCurrentSendable: (state) => state.currentSendable,
        isCurrentChatIsDefault: (state) => {
            return state.currentChat.requestId === "ef3a6e73-0cb5-4b1b-ba71-9a0545cdad26" + "-" + state.user.sap
                || state.currentChat.requestId === "a2174eac-6e96-4546-bbde-425b8e962081" + "-" + state.user.sap
                || state.currentChat.requestId === "abcd974c-9061-48f3-9b4b-e3462cfc6665" + "-" + state.user.sap;
        },
        getCurrentWechatCode: (state) => state.currentWechatCode.code,
        isArtificial: (state) => state.artificial
    },
    mutations: {
    },
    actions: {

    },
    modules: {

    }
})
