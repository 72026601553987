<template>
  <div class="input-area">
    <el-input ref="inputRef"
              v-model="input"
              :placeholder="store.getters.getCurrentSendable ? '发送消息给万物云大模型' : '等待万物云大模型应答'"
              size="large"
              class="input"
              @keypress.enter="sendMessage"
              :disabled="!store.getters.getCurrentSendable">
      <template #suffix>
        <el-icon v-if="store.getters.getCurrentSendable" @click="sendMessage" size="24" class="promotion">
          <Promotion/>
        </el-icon>
        <el-icon v-if="!store.getters.getCurrentSendable" class="is-loading" size="24">
          <Loading/>
        </el-icon>
      </template>
    </el-input>
    <br/>
    <el-text size="small" class="tipsInfo" type="info">
      请自行核实万物云大模型给出的建议和信息
    </el-text>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {Promotion, Loading} from '@element-plus/icons-vue'
import {useStore} from "vuex";
import {ask} from "@/js/api/api";
import {getCurrentEnv} from "@/js/logic/auth";
import {Device} from "@/js/constant/constant";

const input = ref('')
const inputRef = ref()
const store = useStore()
const isMobile = ref(false)
// 自动获取焦点
onMounted(() => {
  if (getCurrentEnv().device === Device.PC) {
    inputRef.value.focus();
    return;
  }
  isMobile.value = true;
})

/**
 * 发送消息
 * @returns {Promise<void>}
 */
const sendMessage = async () => {
  // 不可发送信息
  store.state.currentSendable = false;
  await ask(input.value)
      .then()
      .finally(() => {
        store.state.currentSendable = true;
        //   清空input
        input.value = "";
        if (getCurrentEnv().device === Device.PC) {
          inputRef.value.focus();
        }
      });
}
</script>

<style scoped>
.input-area {
  width: 100%;
  height: 100%;
  background-color: #f6f7fb;
  text-align: center;
}

.input {
  width: 80%;
  max-width: 766px;
  height: 56px;

}

/deep/ .el-input__wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* 阴影效果 */
}

.tipsInfo {
  width: 100%;
  margin-top: 10px;
}

.promotion:hover {
  cursor: pointer;
}
</style>
