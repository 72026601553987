<template>
  <div class="header">
    <div class="logo">
      <el-image class="svg" :src="require('@/assets/logo.png')" />
    </div>
    <div class="button" @click="createNewChat">
      <div class="inner">
        <el-text size="default" class="font">新建聊天</el-text>
        <el-icon size="18" color="#ececec" class="icon">
          <Edit />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Edit } from "@element-plus/icons-vue"
import {addChat} from "@/js/logic/controller";
const createNewChat = () => {
  addChat()
}

</script>

<style scoped>
.header {
  width: 100%;
  height: 100%;
}

.logo {
  height: 50%;
  width: 100%;
}

.button {
  width: 95%;
  height: 50%;
  background-color: #498f73;
  border-radius: 10px;
  margin-top: 10px;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  background-color: #3e7b5f;
}

.inner {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.svg {
  height: 100%;
  width: 50%;
  display: block;
  margin: 0 auto;
}

.icon {
  margin-left: auto;
}

.font {
  margin-left: 8px;
  font-size: 20px;
  color: #ececec;
  margin-top: 5px;
}
</style>