import store from "@/plugin/store/index"
import {getUUID} from "@/js/util/util";
import {Chat} from "@/js/logic/chat";
import {Chat_State} from "@/js/constant/constant";
export const addChat = () => {
    store.state.currentChatState = Chat_State.INIT
    const chat = new Chat(getUUID(), "新对话", []);
    store.state.currentChat = chat;
    store.state.currentRequestId = chat.requestId;
}

export const changeCurrentChat = (requestId) => {
    const all = [...store.getters.getCurrentRecord, ...store.getters.getCurrentDefaultRecord];
    // 遍历当前record
    all.forEach(chat => {
        // 匹配requestId
        if (chat.requestId === requestId) {
            // 切换当前chat
            store.state.currentChat = chat;
            // 切换当前requestId
            store.state.currentRequestId = requestId;
        }
    })
    store.state.currentChatState = Chat_State.CHATTING
}

/**
 * 添加消息
 * @param message RequestMessage / ResponseMessage
 */
export const addMessage = (message) => {
    // 当前Chat为空 - 当前chat的message列表为空，就认为当前chat为空
    if (store.getters.getCurrentChat.messages.length === 0) {
        // 修改当前requestId
        store.state.currentRequestId = store.getters.getCurrentChat.requestId
        // 将当前chat记录到record中
        store.getters.getCurrentRecord.push(store.getters.getCurrentChat);
    }
    // 更新当前chat - 插入 message
    store.getters.getCurrentChat.messages.push(message)
}

/**
 * 滑动返回
 * @param dom 需要挂载的dom， null -> root
 * @param callback 无参回调函数
 * @param once 是否只监听一次
 */
export const slideBack = (dom, callback) => {
    const root = dom ? dom : document.getElementById("app");
    let startX, startY;
    let distX, distY;
    const touchstart = function (e) {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    }
    const touchmove = function (e) {
        const currentX = e.touches[0].clientX;
        const currentY = e.touches[0].clientY;
        distX = startX - currentX;
        distY = startY - currentY;
    }
    const touchend = function () {
        // 垂直移动距离大于水平移动距离 或 移动距离太小 不触发滑动返回
        if (Math.abs(distY) > Math.abs(distX) || distX < 30) return;
        if (!callback || !(callback instanceof Function)) return;
        callback();
        // 去除挂载的事件
        root.removeEventListener("touchstart", touchstart);
        root.removeEventListener("touchmove", touchmove);
        root.removeEventListener("touchend", touchend);
    }
    // 触摸开始事件
    root.addEventListener('touchstart', touchstart);
    root.addEventListener('touchmove', touchmove);
    root.addEventListener("touchend", touchend)
}

/**
 * 去除挂载的事件
 * @param dom 指定的dom - 默认为root
 * @param callback 回调函数
 */
export const unSlidBack = (dom, callback) => {
    const root = dom ? dom : document.getElementById("app");
    if (!callback || !(callback instanceof Function)) console.log("错误的callback");
    root.removeEventListener("touchstart", callback);
    root.removeEventListener("touchmove", callback);
    root.removeEventListener("touchend", callback);
}