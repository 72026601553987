<template>
  <div class="chat">
    <div class="chat-box" id="#chat-box">
      <el-scrollbar ref="scrollbar" :max-height="maxHeight">
        <div id="chat-box-inner" class="chat-box-inner">
          <bubble v-for="(message, index) in store.getters.getCurrentChat.messages"
                  :message="message"
                  :key="index">
          </bubble>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script setup>
import bubble from "@/views/home/components/chat/components/bubble/index.vue";
import {onMounted, ref, nextTick, watch, onUpdated, onBeforeUnmount} from 'vue'
import {useStore} from "vuex";
import {ResponseMessage} from "@/js/logic/message";
import {addMessage} from "@/js/logic/controller";

const maxHeight = ref("700px")
const scrollbar = ref()
const store = useStore()

// 获取当前页面的高度，支持滚动条合理出现
onMounted(() => {
  // 获得class为chat-box的div的高度
  maxHeight.value = document.getElementById('#chat-box').scrollHeight + 'px';
  scrollbar.value.setScrollTop(document.getElementById("chat-box-inner").scrollHeight)

  // 添加监听事件
  window.addEventListener("resize", changeHeight);
})

onUpdated(() => {
  maxHeight.value = document.getElementById('#chat-box').scrollHeight + 'px';
  scrollToBottom()
})

// 销毁前去除监听事件
onBeforeUnmount(() => {
  window.removeEventListener("resize", changeHeight)
})

const changeHeight = () => {
//   获得最新的高度
  maxHeight.value  = document.getElementById('#chat-box').scrollHeight + 'px';
  scrollbar.value.setScrollTop(document.getElementById("chat-box-inner").scrollHeight);
}

const scrollToBottom = async () => {

  console.log(document.getElementById("chat-box-inner").scrollHeight)
  await nextTick(() => {
    scrollbar.value.setScrollTop(document.getElementById("chat-box-inner").scrollHeight)
  })
}

watch(() => store.state.currentChat, () => {
  if (store.getters.isCurrentChatIsDefault
      && store.getters.getCurrentChat.messages.length > 1
      && store.getters.getCurrentChat.messages[store.getters.getCurrentChat.messages.length - 1].content !== "欢迎回来，这里是" + store.getters.getCurrentChat.title) {
    addMessage(new ResponseMessage(store.getters.getCurrentRequestId, "欢迎回来，这里是" + store.getters.getCurrentChat.title))
  }
  scrollToBottom()
})

watch(store.state.currentChat.messages, () => {
  scrollToBottom()
})

</script>

<style scoped>
.chat {
  width: 100%;
  height: 100%;
  background: #f6f7fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-box {
  width: 100%;
  height: 90%;
  margin: 0 auto;
}

.chat-box-inner {
  width: 100%;
}
</style>
