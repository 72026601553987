export function Chat(requestId, title, messages){
    if (!this) return
    this.requestId = requestId;
    this.time = new Date();
    if (!title || title.length <= 3 || title.length >= 10) {
        this.title = "新对话";
    } else {
        this.title = title;
    }
    this.messages = messages;
}