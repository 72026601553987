import {createRouter, createWebHistory} from "vue-router";
import Home from "@/views/home/index.vue"
import WechatLogin from "@/views/login/wechatLogin/index.vue"

const routes = [
    {
        path: '/',
        name: Home,
        component: Home
    },
    {
        path: '/home',
        name: Home,
        component: Home
    },
    {
        path: '/login/wechat',
        name: WechatLogin,
        component: WechatLogin
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router