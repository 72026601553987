<template>
  <div class="welcome">
    <div class="info">
      <div class="icon">
        <el-image class="svg" :src="require('@/assets/logo.png')" />
      </div>
      <el-text class="font">服务历久弥新</el-text>
      <el-text class="font2">SERVICE FOR EVER BETTER</el-text>
    </div>
  </div>
</template>

<style scoped>
.welcome {
  width: 100%;
  height: 100%;
  background: #f6f7fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  width: 240px;
  height: 92px;
}

.icon {
  width: 150px;
  margin: 0 auto;
  display: block;
}

.font {
  font-weight: 500;
  font-size: 36px;
  color: #a8a8a8;
  width: 240px;
  height: 34px;
  margin: 0 auto;
  margin-top: 10px;
  display: block;
}

.font2 {
  font-weight: 500;
  font-size: 16px;
  height: 100%;
  width: 240px;
  color: #a8a8a8;
  margin-top: 4px;
  display: block;
}
</style>