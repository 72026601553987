export const MessageType = {
    TEXT: 'text'
}

export const Chat_State = {
    INIT: 'init',
    CHATTING: 'chatting',
}

export const MessageDirection = {
    REQUEST: 'REQUEST',
    RESPONSE: 'RESPONSE'
}

export const Device = {
    PC: 'pc',
    MOBILE: 'mobile'
}