import axios from "axios";
import store from "@/plugin/store"

const http = axios.create({
    baseURL: "http://legal-kftest.onewo.com/api",
    timeout: 1000
})

http.interceptors.request.use(
    config => {
        if (store.getters.getCurrentUser.token) {
            config.headers["Access-Token"] = store.getters.getCurrentUser.token
        }
        return config;
    },
    error => {
        console.log("request error:" + error);
        Promise.reject(error)
    }
);

http.interceptors.response.use(
    response => {
        // if (response.data.code !== 200) {
        //     ElMessage({
        //         type: 'error',
        //         message: response.data.message,
        //         center: true,
        //     })
        // }
        return response.data
    },
    error => {
        console.log("response error:" + error);
        Promise.reject(error)
    }
)

export default http;