<template>
  <div :class="{ 'checkedRecord': props.checked, record: !props.checked }" @click="changeChat">
    <div class="inner">
      <el-text truncated class="font" size="large">{{ props.record.title }}</el-text>
      <el-popover
          placement="right"
          :width="120"
          trigger="click"
          :hide-after="0"
          v-if="!store.getters.isCurrentChatIsDefault && !isMobile">
        <template #reference>
          <el-icon class="more-action-icon" v-if="checked" size="18" @click="actionVisible"><MoreFilled /></el-icon>
        </template>
        <template #default>
          <div class="action">
            <div class="top" @click="showChangeTitleDialog">
              <el-icon><EditPen /></el-icon>
              修改名称
            </div>
            <div class="bottom" @click="showDeleteChatDialog">
              <el-icon><Delete /></el-icon>
              删除聊天
            </div>
          </div>
        </template>
      </el-popover>
    </div>
    <el-dialog
        v-model="changeTitleDialogVisible"
        title="修改名称"
        width="500"
        @close="hiddenChangeTitleDialog">
      <el-form>
        <el-form-item>
          <el-input v-model="newTitle" size="large" placeholder="修改对话名称"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button size="large" color="#5276e4" class="right" @click="changeTitle">确认</el-button>
            <el-button size="large" class="left" @click="hiddenChangeTitleDialog">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
        v-model="deleteChatVisible"
        title="删除聊天"
        width="500"
        @close="hiddenDeleteChatDialog">
      <el-form>
        <el-form-item>
          <el-text>
            你确定要
            <el-text size="large" tag="b" type="danger">
              删除
            </el-text>
            <el-text type="primary">
              [{{ store.getters.getCurrentChat.title }}]
            </el-text>
            聊天吗？
          </el-text>
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button size="large" color="#5276e4" class="right" @click="deleteChat">确认</el-button>
            <el-button size="large" class="left" @click="hiddenDeleteChatDialog">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {changeCurrentChat} from "@/js/logic/controller";
import {MoreFilled, Delete, EditPen} from "@element-plus/icons-vue";
import {changeNewTitle, deleteChatByRequestId} from "@/js/api/api";
import {useStore} from "vuex";
import {Device} from "@/js/constant/constant";
import {getCurrentEnv} from "@/js/logic/auth";

const props = defineProps({
  record: Object,
  checked: Boolean
})
const actionVisible = ref(false);
const changeTitleDialogVisible = ref(false);
const deleteChatVisible = ref(false);
const store = useStore()
const isMobile = ref(false)
const newTitle = ref("");

onMounted(() => {
  const env = getCurrentEnv();
  isMobile.value = (env.device === Device.MOBILE)
})

const showChangeTitleDialog = () => {
  changeTitleDialogVisible.value = true;
}
const showDeleteChatDialog = () => {
  deleteChatVisible.value = true;
}
const hiddenDeleteChatDialog = () => {
  deleteChatVisible.value = false;
}
const hiddenChangeTitleDialog = () => {
  changeTitleDialogVisible.value = false;
  newTitle.value = "";
}
const changeTitle = () => {
  const requestId = store.getters.getCurrentRequestId;
  const title = newTitle.value;
  changeNewTitle(requestId, title);
  newTitle.value = "";
  hiddenChangeTitleDialog()
}
const deleteChat = () => {
  const requestId = store.getters.getCurrentRequestId;
  deleteChatByRequestId(requestId);
  hiddenDeleteChatDialog()
}
const changeChat = () => {
  changeCurrentChat(props.record.requestId)
}
</script>

<style scoped>
.record {
  margin: 0 auto;
  width: 90%;
  height: 32px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
}

.record:hover {
  cursor: pointer;
  background-color: #dadee7;
  border-radius: 10px;
}

.font {
  margin-left: 10px;
  height: 100%;
  width: 95%;
  line-height: 32px;
  color: #212121;
}

.checkedRecord {
  margin: 0 auto;
  width: 90%;
  height: 32px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  background-color: #4777ec;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.checkedRecord:hover {
  cursor: pointer;
}

.checkedRecord .font {
  color: #ffffff;
}

.inner {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-action-icon {
  margin-right: 10px;
  color: #f6f7fb;
}

.more-action-icon:hover {
  color: #43506c;
}

.action {
  height: 60px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.action .top {
  width: 100%;
  height: 49%;
  color: white;
  background-color: #909399;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px white;
}

.action .bottom {
  width: 100%;
  height: 49%;
  color: white;
  background-color: #F56C6C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px white;
}

.action .top:hover,
.action .bottom:hover {
  cursor: pointer;
}

.action .top:active,
.action .bottom:active {
  color: #5276e4;
}

.buttons {
  width: 100%;
  height: 100%;
}

.buttons .right {
  margin-left: 15px;
}

.buttons .right,
.buttons .left {
  float: right;
}

</style>
