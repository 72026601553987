<template>
  <div class="sidebar">
    <mheader class="header"></mheader>
    <el-scrollbar class="record-area" :max-height="maxHeight" id="#aside-scrollbar">
      <div class="default" id="default">
        <el-icon v-if="!isMobile" color="red"><CollectionTag /></el-icon>
        <div class="default-record">
          <mrecord v-for="(record, index) in store.getters.getCurrentDefaultRecord"
                   class="mt5"
                   :key="index"
                   :record="record"
                   :checked="record.requestId === store.getters.getCurrentRequestId">
          </mrecord>
        </div>
      </div>
      <el-divider class="divider"></el-divider>
      <div id="inner">
        <mrecord v-for="(record, index) in store.getters.getCurrentRecord"
                 class="mt5"
                 :key="index"
                 :record="record"
                 :checked="record.requestId === store.getters.getCurrentRequestId">
        </mrecord>
      </div>
    </el-scrollbar>
    <foot class="foot"></foot>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import mheader from "@/views/home/components/sidebar/components/header/index.vue"
import mrecord from "./components/record/index.vue"
import foot from "@/views/home/components/sidebar/components/foot/index.vue"
import { useStore } from "vuex";
import { CollectionTag } from "@element-plus/icons-vue"
import {getCurrentEnv} from "@/js/logic/auth";
import {Device} from "@/js/constant/constant";

const maxHeight = ref("700px")
const isMobile = ref(true)
const store = useStore()

onMounted(() => {
  isMobile.value = (getCurrentEnv().device === Device.MOBILE)
})

// 获取当前页面的高度，支持滚动条合理出现
onMounted(() => {
  // 获得class为chat-box的div的高度
  maxHeight.value = document.getElementsByClassName('sidebar')[0].clientHeight
      - document.getElementsByClassName('header')[0].clientHeight
      - document.getElementsByClassName('foot')[0].offsetHeight
      - 20 + "px";
  document.getElementById("#aside-scrollbar").setAttribute("style", "height:" + maxHeight.value)
})
</script>

<style scoped>
.record-area {
  padding: 0;
  margin: 25px 0;
  height: auto;
  width: 100%;
}

.sidebar {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  position: relative;
}

.header {
  width: 90%;
  height: 80px;
  margin: 0 auto;
}

.foot {
  width: 90%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 5%;
}

.default {
  width: 90%;
  margin: 0 auto;
  padding: 0;
}

.divider {
  width: 90%;
  margin: 14px auto;
  padding: 0;
}

.mt5 {
  margin-top: 5px;
}

.default-record {
  margin-top: -10px;
}
</style>