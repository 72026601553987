<template>
  <div class="mheader">
    <el-row class="container">
      <el-col :span="8">
        <div class="operation" id="#operation">
          <el-icon color="#409efc" class="icon" id="#iconHistroy" @click="showHistoryDraw">
            <ChatDotRound/>
          </el-icon>
          <el-divider class="divider" direction="vertical"/>
          <el-icon color="#409efc" class="icon" id="#iconNew" @click="createNewChat">
            <ChatLineRound/>
          </el-icon>
        </div>
      </el-col>
      <el-col :span="8">
        <div v-if="store.getters.getCurrentRequestId !== ''" class="title" ref="titleInfo" v-click-outside="showMoreAction">
          <el-text class="titleInfo" size="large" tag="b" truncated>{{ store.getters.getCurrentChat.title }}</el-text>
        </div>
      </el-col>
      <el-col :span="8">
        <el-avatar size="large" class="avatar" id="#avatar">{{ store.getters.getCurrentUser.name.substring(0, 1).toUpperCase() }}
        </el-avatar>
      </el-col>
    </el-row>

    <el-drawer
        ref="historyDrawer"
        v-model="chatRecordVisible"
        title="对话历史"
        direction="ltr"
        size="260px"
        id="#drawer"
        :show-close="false">
      <mrecord v-for="(record, index) in store.getters.getCurrentDefaultRecord"
               :key="index"
               :record="record"
               :checked="record.requestId === store.getters.getCurrentRequestId">
      </mrecord>
      <el-divider class="drawer-divider"></el-divider>
      <mrecord v-for="(record, index) in store.getters.getCurrentRecord"
               :key="index"
               :record="record"
               :checked="record.requestId === store.getters.getCurrentRequestId">
      </mrecord>
    </el-drawer>
    <el-popover
        placement="bottom-start"
        :width="160"
        trigger="click"
        :hide-after="0"
        v-if="!store.getters.isCurrentChatIsDefault"
        ref="moreActions"
        :virtual-ref="titleInfo"
        virtual-triggering>
      <template #reference>
        <div>asd</div>
      </template>
      <template #default>
        <div class="action">
          <div class="top" @click="showChangeTitleDialog">
            <el-icon><EditPen /></el-icon>
            修改名称
          </div>
          <div class="bottom" @click="showDeleteChatDialog">
            <el-icon><Delete /></el-icon>
            删除聊天
          </div>
        </div>
      </template>
    </el-popover>
    <el-dialog
        v-model="changeTitleDialogVisible"
        title="修改名称"
        width="360px"
        top="40vh"
        @close="hiddenChangeTitleDialog">
      <el-form>
        <el-form-item>
          <el-input v-model="newTitle" size="large" placeholder="修改对话名称"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button size="large" color="#5276e4" class="right" @click="changeTitle">确认</el-button>
            <el-button size="large" class="left" @click="hiddenChangeTitleDialog">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
        v-model="deleteChatVisible"
        title="删除聊天"
        width="360px"
        top="40vh"
        @close="hiddenDeleteChatDialog">
      <el-form>
        <el-form-item>
          <el-text>
            你确定要
            <el-text size="large" tag="b" type="danger">
              删除
            </el-text>
            <el-text type="primary">
              [{{ store.getters.getCurrentChat.title }}]
            </el-text>
            聊天吗？
          </el-text>
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button size="large" color="#5276e4" class="right" @click="deleteChat">确认</el-button>
            <el-button size="large" class="left" @click="hiddenDeleteChatDialog">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import {ChatDotRound, ChatLineRound, Delete, EditPen} from "@element-plus/icons-vue"
import {onMounted, ref, unref} from "vue";
import {useStore} from "vuex";
import mrecord from "@/views/home/components/sidebar/components/record/index.vue"
import {Chat_State} from "@/js/constant/constant";
import {addChat, slideBack} from "@/js/logic/controller";
import {changeNewTitle, deleteChatByRequestId} from "@/js/api/api";
import { ClickOutside as vClickOutside } from 'element-plus'

const store = useStore()
const chatRecordVisible = ref(false)
const maxHeight = ref("844px")
const moreActions = ref()
const titleInfo = ref()
const changeTitleDialogVisible = ref(false);
const deleteChatVisible = ref(false);
const newTitle = ref("");
const historyDrawer = document.getElementById("#drawer")
const createNewChat = () => {
  // 修改 chat state 的状态
  store.state.currentChatState = Chat_State.INIT
  // 修改当前chat
  addChat();
}
onMounted(() => {
  maxHeight.value = document.getElementById("#drawer").offsetHeight + "px"
})
onMounted(() => {
  // 获取父元素高度
  if (!document.getElementById("#operation")) return
  const height = document.getElementById("#operation").offsetHeight;
  const fontSize = height - 16;
  console.log(height)
  // 设置 class icon line-height
  const iconHistory = document.getElementById("#iconHistroy");
  const iconNew = document.getElementById("#iconNew");
  iconHistory.setAttribute('style', "line-height: " + height + "px")
  iconHistory.setAttribute('style', "font-size: " + fontSize + "px")
  iconNew.setAttribute('style', "line-height: " + height + "px")
  iconNew.setAttribute('style', "font-size: " + fontSize + "px")
})

const showHistoryDraw = () => {
  chatRecordVisible.value = true
  // 挂载模拟滑动返回事件
  slideBack(historyDrawer, hiddenHistoryDraw)
}
const hiddenHistoryDraw = () => {
  chatRecordVisible.value = false
}
const showMoreAction = () => {
  console.log(store.getters.getCurrentChat.requestId)
  if (store.getters.getCurrentChat.requestId) {
    if (unref(moreActions).popperRef)
      unref(moreActions).popperRef.hidden
  }
}
const showChangeTitleDialog = () => {
  changeTitleDialogVisible.value = true;
}
const showDeleteChatDialog = () => {
  deleteChatVisible.value = true;
}
const hiddenDeleteChatDialog = () => {
  deleteChatVisible.value = false;
}
const hiddenChangeTitleDialog = () => {
  changeTitleDialogVisible.value = false;
  newTitle.value = "";
}
const changeTitle = () => {
  const requestId = store.getters.getCurrentRequestId;
  const title = newTitle.value;
  changeNewTitle(requestId, title);
  newTitle.value = "";
  hiddenChangeTitleDialog();
}
const deleteChat = () => {
  const requestId = store.getters.getCurrentRequestId;
  deleteChatByRequestId(requestId);
  hiddenDeleteChatDialog();
}

</script>

<style scoped>
.mheader {
  height: 100%;
  width: 100%;
  background: #f6f7fb;
}

.container {
  height: 96%;
  width: 98%;
  margin: 0 auto;
}

.operation {
  margin-top: 10px;
  height: 70%;
  width: 90%;
  background-color: #377df7;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 100%;
  width: 50%;
  color: #212121;
}

.icon:active {
  color: white;
}

.divider {
  height: 80%;
  margin: 0;
  padding: 0;
}

.title {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.titleInfo {
  width: 90%;
  margin: 10px auto;
  color: black;
  text-align: center;
}

.avatar {
  float: right;
  background-color: #3e7b5f;
  font-size: 20px;
  margin-top: 5px;
}

.chatName /deep/ .el-input__inner {
  border: none; /* 移除默认边框 */
  border-bottom: 1px solid black; /* 添加下边框 */
  text-align: center;
}

.chatName /deep/ .el-input__wrapper {
  background-color: #f6f7fb;;
  box-shadow: none;
}

/deep/ .el-drawer__body {
  padding: 0;
  margin: 0;
}

.record-area {
  height: 100%;
  width: 100%;
}

.drawer-divider {
  width: 90%;
  padding: 0;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
}

.action {
  height: 80px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.action .top {
  width: 100%;
  height: 49%;
  color: white;
  background-color: #909399;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px white;
}

.action .bottom {
  width: 100%;
  height: 49%;
  color: white;
  background-color: #F56C6C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px white;
}

.action .top:hover,
.action .bottom:hover {
  cursor: pointer;
}

.action .top:active,
.action .bottom:active {
  color: #5276e4;
}

.buttons {
  width: 100%;
  height: 100%;
}

.buttons .right {
  margin-left: 15px;
}

.buttons .right,
.buttons .left {
  float: right;
}
</style>
<script setup>
</script>