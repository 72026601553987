import {ElMessage} from "element-plus";
import {Chat_State} from "@/js/constant/constant";
import {RequestMessage, ResponseMessage} from "@/js/logic/message";
import {addChat, addMessage} from "@/js/logic/controller";
import store from "@/plugin/store/index"
import http from "@/plugin/axios";
import {getCurrentEnv} from "@/js/logic/auth";
import {Chat} from "@/js/logic/chat";

export const ask = async (message) => {
    // 消息判空
    if (!checkNotEmpty(message)) return;
    // 情况 - 初始化聊天
    if (store.getters.getCurrentChatState === Chat_State.INIT) initChat(message);
    addMessage(new RequestMessage(store.getters.getCurrentRequestId, message))
    // 获取返回信息 - 并加以展示
    await getResponseMessage(message);
    // 修改对话标题
    await changeChatTitle();
}
const checkNotEmpty = (val) => {
    if (!val || val.trim() === '') {
        ElMessage({
            type: 'error',
            message: '消息不能为空！',
            center: true,
        })
        return false;
    }
    return true
}
const initChat = () => {
    addChat();
    store.state.currentChatState = Chat_State.CHATTING
}
const changeChatTitle = async () => {
    // 默认对话不支持修改
    if (store.getters.isCurrentChatIsDefault) return;
    if (store.getters.getCurrentChat.messages.length === 6
        || store.getters.getCurrentChat.messages.length === 10) {
        changeCurrentTitle();
    }
}
const getResponseMessage = async (message) => {
    // 新增message
    addMessage(new ResponseMessage(store.state.currentRequestId, ""));
    // 接受对话消息
    let response = await fetch("http://legal-kftest.onewo.com/api/chat/ask", {
        method: 'post',
        body: JSON.stringify({
            requestId: store.state.currentRequestId,
            query: message
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Token': store.getters.getCurrentUser.token
        }
    })
    // 错误的情况
    if (!response.ok) {
        let currentMessage = store.state.currentChat.messages[store.state.currentChat.messages.length - 1]
        currentMessage.content = "发生了未知错误，请重试！";
        store.state.currentChat.messages.pop();
        store.state.currentChat.messages.push(currentMessage);
        return
    }
    const reader = response.body.getReader();
    const textDecoder = new TextDecoder()
    let result = true;
    let output = '';
    while (result) {
        const {done, value} = await reader.read();
        if (done) {
            result = false
            break;
        }
        const chunkTexts = textDecoder.decode(value).split("@#@");
        chunkTexts.forEach(text => {
            if (!text) return;
            let obj;
            try {
                obj = JSON.parse(text);
                output += obj.content ? obj.content : "";
            } catch (error) {
                console.log(text)
            }
        })

        let currentMessage = store.state.currentChat.messages[store.state.currentChat.messages.length - 1]
        currentMessage.content = output;
        store.state.currentChat.messages.pop();
        store.state.currentChat.messages.push(currentMessage);
    }
}

/**
 * 登录
 */
export const login = () => {
    if (!store.state.currentWechatCode.useable) {
        ElMessage.error("当前code已被使用，请重新进入");
        localStorage.clear()
        return;
    }
    http.get("/auth/login/work-wechat", {params: {code: store.getters.getCurrentWechatCode}})
        .then(resp => {
            if (resp.code !== 200) return;
            store.state.currentWechatCode.useable = false;
            const {sap, name, token} = resp.data;
            store.state.user.name = name;
            store.state.user.sap = sap;
            store.state.user.token = token;
            localStorage.setItem("user", JSON.stringify({sap: sap, name: name, token: token}));
            loadHistoryRecord();
        })
}

/**
 * 获得历史数据
 */
export const loadHistoryRecord = () => {
    console.log("asd")
    http.get("/chat/getHistory").then(resp => {
        if (resp.code === 200) {
            store.state.currentRecord = []
            store.state.currentRecord = resp.data;
        } else {
            // 清空当前 localStorage
            localStorage.clear()
            if (getCurrentEnv().isWorkWecaht) {
                login()
            }
        }
    })
}

export const changeCurrentTitle = () => {
    if (store.getters.isCurrentChatIsDefault) {
        ElMessage.error("默认对话不支持删除操作！");
        return;
    }
    http.get("/chat/changeTitle", {params: {requestId: store.getters.getCurrentRequestId}})
        .then(resp => {
            store.state.currentChat.title = resp.data.data;
        })
}

export const changeNewTitle = (requestId, title) => {
    if (!title || title.trim() === '') {
        ElMessage.error("新标题不可为空！");
        return;
    }
    if (store.getters.getCurrentChat.requestId !== requestId) {
        ElMessage.error("只能操作当前的对话！");
        return;
    }
    if (store.getters.isCurrentChatIsDefault) {
        ElMessage.error("默认对话不支持删除操作！");
        return;
    }
    http.get("/chat/changeTitleByUser", {params: {requestId: requestId, title: title}})
        .then(resp => {
            console.log(resp);
            store.state.currentChat.title = title;
        });
}

export const deleteChatByRequestId = (requestId) => {
    if (store.getters.getCurrentChat.requestId !== requestId) {
        ElMessage.error("只能操作当前的对话！");
        return;
    }
    if (store.getters.isCurrentChatIsDefault) {
        ElMessage.error("默认对话不支持删除操作！");
        return;
    }
    http.get("/chat/deleteChat", {params: {requestId: requestId}})
        .then(resp => {
            console.log(resp)
            store.state.currentChat = new Chat("","",[]);
            store.state.currentRequestId = '';
            store.state.currentChatState = Chat_State.INIT;
            store.state.currentRecord = store.state.currentRecord.filter(chat => {
                return chat.requestId !== requestId;
            })
        });
}