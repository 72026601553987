<template>
  <el-container class="home">
    <el-header class="header" v-if="isMobile">
      <mheader></mheader>
    </el-header>
    <el-aside class="sidebar" v-if="!isMobile">
      <sidebar></sidebar>
    </el-aside>
    <el-container>
      <el-main :class="{ 'main-mobile':isMobile, 'main-pc': !isMobile }">
        <welcome v-if="store.getters.getCurrentChatState === Chat_State.INIT"></welcome>
        <mchat class="chat" v-if="store.getters.getCurrentChatState === Chat_State.CHATTING"></mchat>
      </el-main>
      <el-footer class="foot" :class="{ 'foot-default': store.getters.isCurrentChatIsDefault }" >
        <div class="artificial">
          <el-tag round type="danger" size="small" v-if="store.getters.isCurrentChatIsDefault" @click="toArtificial">转人工服务</el-tag>
        </div>
        <inputBox></inputBox>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {Chat_State, Device} from "@/js/constant/constant";
import {getCurrentEnv} from "@/js/logic/auth.js";
import {useStore} from 'vuex'
import {loadHistoryRecord, login} from "@/js/api/api";
import router from "@/plugin/router";

import sidebar from "@/views/home/components/sidebar/index.vue"
import welcome from "@/views/home/components/welcome/index.vue"
import inputBox from "@/views/home/components/input/index.vue"
import mheader from "@/views/home/components/header/index.vue"
import mchat from "@/views/home/components/chat/index.vue"

const isMobile = ref(false)
const store = useStore();

// 鉴权
onMounted(() => {
  // 获取当前运行环境
  const env = getCurrentEnv();
  isMobile.value = (env.device === Device.MOBILE)
  // 判断是否已登录
  if (router.currentRoute.value.query.code) {
    store.state.currentWechatCode = { code: router.currentRoute.value.query.code, useable: true }
  }
  if (localStorage.getItem("user")) {
    // 加载本地缓存数据
    store.state.user = JSON.parse(localStorage.getItem("user"));
    loadHistoryRecord();
    return
  }
  // 企微环境
  if (env.isWorkWecaht) {
    store.state.currentWechatCode = { code: router.currentRoute.value.query.code, useable: true }
    login();
  } else {
    router.push("/login/wechat")
  }
})

const toArtificial = () => {
  store.state.artificial = true;
}
</script>

<style scoped>
.sidebar {
  padding: 0;
  width: 260px;
  height: 100%;
}

.home {
  padding: 0;
  width: 100%;
  height: 100%;
}

.header {
  height: 8%;
  width: 100%;
  background: #f6f7fb;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.9);
}

.main-mobile {
  padding: 0;
  width: 100%;
  height: 80%;
}

.main-pc {
  padding: 0;
  width: 100%;
  height: 88%;
  background: #f6f7fb;
}

.foot {
  padding: 0;
  height: 10%;
  width: 100%;
  background-color: #f6f7fb;
}

.foot-default {
  height: 13%;
}

.main-pc .chat {
  width: 60%;
  margin: 0 auto;
}

.artificial {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 20%;
  border-radius: 5px;
  padding: 2px 10px;
  background-color: #f6f7fb;
}

.artificial:hover {
  cursor: pointer;
}
</style>
