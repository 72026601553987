import { Device } from "@/js/constant/constant";
import store from "@/plugin/store/index"

/**
 * 获取当前环境，企业微信/PC Web/Mobile Web
 */
export function getCurrentEnv() {
//     判断 pc - mobile
//     获取当前窗口大小
    const device = window.innerWidth <= 768 ? Device.MOBILE : Device.PC;
    // 判断是否为企业微信环境
    const isWorkWechat = /wxwork/i.test(navigator.userAgent)
    const agent = navigator.userAgent;
    let os = 'Unknown'
    if (/Windows/i.test(agent)) {
        os = 'Windows';
    } else if (/Macintosh/i.test(agent)) {
        os = 'Mac';
    } else if (/Linux/i.test(agent)) {
        os = 'Linux';
    } else if (/iPhone|iPad|iPod/i.test(agent)) {
        os = 'iOS';
    } else if (/Android/i.test(agent)) {
        os = 'Android';
    } else {
        os = 'Unknown';
    }
    return new Env(device, isWorkWechat, os);
}

export function auth() {
    if (store.getters.getCurrentUser && store.getters.getCurrentUser.sap !== '0') return store.getters.getCurrentUser;
    const env = getCurrentEnv();
    let userInfo = {};
    if (env.isWorkWecaht) {
        userInfo = workWechatAuth();
    } else {
        userInfo = idmAuth();
    }
    store.state.user = { name: '', sap: '', token: '' }
    return userInfo;
}

export function workWechatAuth() {

}

export function idmAuth() {

}

function Env(device, isWorkWechat, os) {
    this.device = device;
    this.isWorkWecaht = isWorkWechat;
    this.os = os;
}

