import { MessageDirection } from '@/js/constant/constant'
export function RequestMessage(requestId, content) {
    return new Message(requestId, content, MessageDirection.REQUEST);
}

export function ResponseMessage(requestId, content) {
    return new Message(requestId, content, MessageDirection.RESPONSE);
}

function Message(requestId, content, direction) {
    this.requstId = requestId;
    this.content = content;
    this.direction = direction;
}